import * as _possibleTypedArrayNames2 from "possible-typed-array-names";
var _possibleTypedArrayNames = _possibleTypedArrayNames2;
try {
  if ("default" in _possibleTypedArrayNames2) _possibleTypedArrayNames = _possibleTypedArrayNames2.default;
} catch (e) {}
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
var possibleNames = _possibleTypedArrayNames;
var g = typeof globalThis === "undefined" ? _global : globalThis;

/** @type {import('.')} */
exports = function availableTypedArrays() {
  var /** @type {ReturnType<typeof availableTypedArrays>} */out = [];
  for (var i = 0; i < possibleNames.length; i++) {
    if (typeof g[possibleNames[i]] === "function") {
      // @ts-expect-error
      out[out.length] = possibleNames[i];
    }
  }
  return out;
};
export default exports;